import { CookiesEvents, CookiesPopup } from '../plugins/PluginCookies.ts'
// import '../types.ts';

/**
 * Class for controll cookies popup.
 */
class Popup extends CookiesPopup {
  reopen(): void {
    this.setPopupInputsState()
    this.hideElement('[data-item="bw-cookies-custom"]')
    this.showElement('[data-item="bw-cookies-general"]')

    this.addEventHandlers()
  }

  /**
   * Adds event controllers for buttons in popup.
   */
  addEventHandlers(): void {
    this.cookiesSettingsBox.classList.remove('hidden')
    this.lockScroll()

    const btnAcceptAll = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="bw-cookies-accept-all"]',
    )
    const btnSaveCustom = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="bw-cookies-save-custom"]',
    )
    const btnCustomSet = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="bw-cookies-custom-set"]',
    )
    const btnDisagreeAll = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="bw-cookies-disagree-all"]',
    )
    const btnCloseGeneral = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="close-general"]',
    )
    const btnCloseSettings = this.cookiesSettingsBox.querySelectorAll(
      '[data-item="close-settings"]',
    )
    const btnReopen = document.querySelector(
      '[data-cookies-btn-reopen="reopen"]',
    )

    if (btnReopen) {
      btnReopen.classList.add('hidden')
    }

    btnCloseGeneral.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.hideElement('[data-item="bw-cookies-general"]')
      }),
    )

    btnCloseSettings.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.hideElement('[data-item="bw-cookies-custom"]')
      }),
    )

    btnAcceptAll.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.setAllCookiesAgree(true)
        this.saveInputsState(true)
        location.reload()
      }),
    )

    btnDisagreeAll.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.setAllCookiesAgree(false)
        window.CookiesController.disallowAll()
        this.cookiesSettingsBox.classList.add('hidden')
        this.unlockScroll()
        this.clearStorage()
        this.saveInputsState(false, false)
        location.reload()
      }),
    )

    btnCustomSet.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.showElement('[data-item="bw-cookies-custom"]')
        this.hideElement('[data-item="bw-cookies-general"]')
      }),
    )

    btnSaveCustom.forEach((btn) =>
      btn.addEventListener('click', () => {
        this.setCookiesCustomAgree()
        this.clearStorage()
        this.saveInputsState(false, true)
        location.reload()
      }),
    )
  }

  /**
   * Hides element by adding .hidden
   *
   * @param {string} selector CSS selector
   */
  hideElement(selector: string): void {
    const customSettingsBox = this.cookiesSettingsBox.querySelector(selector)

    if (customSettingsBox) {
      customSettingsBox.classList.add('hidden')
    }
  }

  /**
   * Shows element by removing .hidden
   *
   * @param {string} selector CSS selector
   */
  showElement(selector: string): void {
    const customSettingsBox = this.cookiesSettingsBox.querySelector(selector)

    if (customSettingsBox) {
      customSettingsBox.classList.remove('hidden')
    }
  }

  /**
   * Sets cookies agreement by checkboxes values.
   */
  setCookiesCustomAgree(): void {
    // Get all elemenst with data-cookie attribute
    this.cookiesSettingsBox
      .querySelectorAll('[data-cookie]')
      .forEach((cookieElement) => {
        // Split atributte value to get seperate cookies ids
        const cookieIds = (
          cookieElement.getAttribute('data-cookie') as string
        ).split(';')

        cookieIds.forEach((cookieId) => {
          // Get current value of cookie agreement if is defined
          const cookieValueInController = window.CookiesController.cookies.get(
            cookieId,
          )
            ? window.CookiesController.cookies.get(cookieId)
            : true

          if (cookieValueInController) {
            // Set cookie agreement as logical quotient of current value and checkbox value
            window.CookiesController.addCookie(
              cookieId,
              cookieValueInController &&
                (cookieElement as HTMLInputElement).checked,
            )
          }
        })
      })

    this.cookiesSettingsBox.classList.add('hidden')
    this.unlockScroll()
  }

  setPopupInputsState(): void {
    const stateJson = localStorage.getItem('cookiesInputState')

    if (stateJson) {
      const state = JSON.parse(stateJson)

      state.forEach((item: { id: string; value: boolean }) => {
        const input = this.cookiesSettingsBox.querySelector(
          `[data-cookie]#${item.id}`,
        )

        if (input) {
          ;(input as HTMLInputElement).checked = item.value
        }
      })
    }
  }

  saveInputsState(all = false, checkValue = true): void {
    const state: { id: string; value: boolean }[] = []

    this.cookiesSettingsBox
      .querySelectorAll('[data-cookie]')
      .forEach((elem) => {
        const isNecessary = elem.getAttribute('data-cookie')
          ? (elem.getAttribute('data-cookie') as string).includes('necessary')
          : false
        const checked =
          (checkValue ? (elem as HTMLInputElement).checked : false) ||
          isNecessary ||
          all

        state.push({ id: elem.id, value: checked })
      })

    localStorage.setItem('cookiesInputState', JSON.stringify(state))
  }

  /**
   *
   * Sets all cookies agreement.
   *
   * @param {boolean} agree if true mark all cookies as accepted
   */
  setAllCookiesAgree(agree: boolean): void {
    // Get all elemenst with data-cookie attribute
    this.cookiesSettingsBox
      .querySelectorAll('[data-cookie]')
      .forEach((cookieElement) => {
        // Split atributte value to get seperate cookies ids
        const cookieIds = (
          cookieElement.getAttribute('data-cookie') as string
        ).split(';')

        cookieIds.forEach((cookieId) => {
          //   Set cookie agreement as agree value
          window.CookiesController.addCookie(cookieId, agree)
        })
      })

    this.cookiesSettingsBox.classList.add('hidden')
    this.unlockScroll()
  }

  isNewCookie(): boolean {
    let isCookieUndefined = false

    // Get all elemenst with data-cookie attribute
    this.cookiesSettingsBox
      .querySelectorAll('[data-cookie]')
      .forEach((cookieElement) => {
        // Split atributte value to get seperate cookies ids
        if (window.CookiesController.cookies) {
          const cookieIds = (
            cookieElement.getAttribute('data-cookie') as string
          ).split(';')

          cookieIds.forEach((cookieId) => {
            //   Set isCookieUndefined as true if cookie is undefined
            isCookieUndefined =
              isCookieUndefined ||
              window.CookiesController.cookies.get(cookieId) === undefined
          })
        }
      })

    return isCookieUndefined
  }

  lockScroll(): void {
    const html = document.getElementsByTagName('html')[0]
    html.classList.add('popup-active')
  }

  unlockScroll(): void {
    const html = document.getElementsByTagName('html')[0]
    html.classList.remove('popup-active')

    const btnReopen = document.querySelector(
      '[data-cookies-btn-reopen="reopen"]',
    )

    if (btnReopen) {
      btnReopen.classList.remove('hidden')
    }
  }
}

/**
 * Listen for bw-cookies-popup and create Popup class instance.
 */
window.addEventListener(CookiesEvents.POPUP, () => {
  const cookiesBox = document.querySelector('[data-item="bw-cookies-settings"]')

  if (cookiesBox && !window.cookiesPopup) {
    window.cookiesPopup = new Popup(cookiesBox)
    // window.removeEventListener(CookiesEvents.ACTION, this);
  }
})

window.addEventListener('load', () => {
  const btnReopen = document.querySelector('[data-cookies-btn-reopen="reopen"]')

  if (btnReopen) {
    btnReopen.addEventListener('click', () => {
      if (window.cookiesPopup) {
        window.cookiesPopup.reopen()
      }
    })
  }
})
